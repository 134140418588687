<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="1000px"
      class="dialogForm"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
    <div>
      <div class="minTitle">总账藏品清单</div>
      <div style="margin-left: 25px">已选{{ generalList.length }}件/套 藏品</div>
    </div>
    <el-table
        :data="generalList"
        size="small"
        style="padding-left: 20px"
        height="300px"
        ref="multipleTable1"
        class="table"
        :header-cell-style="{background:'#F9DFDF'}"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="图标" width="120px">
        <template slot-scope="scope">
          <div class="flex_a_c">
            <div v-if="scope.row.collectionData.carded == 0"
                 class="icon-wenwukapian iconfont listIcon"></div>
            <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.collectionData.picMasterViewUrl"
                :fit="'cover'"
                :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
            >
              <div slot="error" class="image-slot">
                <el-image
                    style="width: 36px; height: 36px"
                    :src="require('@/assets/img/default.png')"
                    :fit="'cover'">
                </el-image>
              </div>
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="collectionName" label="藏品名称"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.collectionName }}
        </template>
      </el-table-column>
      <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.helpNum }}
        </template>
      </el-table-column>
      <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.generalNum }}
        </template>
      </el-table-column>
      <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.oneTypeName }}
        </template>
      </el-table-column>
      <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
        </template>
      </el-table-column>
      <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.identified == 0 ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ getTypeList(scope.row.collectionData.collectionType) }}
        </template>
      </el-table-column>
      <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.integrityInfo }}
        </template>
      </el-table-column>
      <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.integrity }}
        </template>
      </el-table-column>
    </el-table>


    <div class="minTitle">辅助账藏品清单</div>
    <div style="margin-left: 25px">已选{{ auxiliaryList.length }}件/套 藏品</div>
    <el-table
        :data="auxiliaryList"
        size="small"
        style="padding-left: 20px"
        height="300px"
        class="table"
        ref="multipleTable2"
        :header-cell-style="{background:'#F9DFDF'}"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="图标" width="120px">
        <template slot-scope="scope">
          <div class="flex_a_c">
            <div v-if="scope.row.collectionData.carded == 0"
                 class="icon-wenwukapian iconfont listIcon"></div>
            <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.collectionData.picMasterViewUrl"
                :fit="'cover'"
                :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
            >
              <div slot="error" class="image-slot">
                <el-image
                    style="width: 36px; height: 36px"
                    :src="require('@/assets/img/default.png')"
                    :fit="'cover'">
                </el-image>
              </div>
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="collectionName" label="藏品名称"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.collectionName }}
        </template>
      </el-table-column>
      <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.helpNum }}
        </template>
      </el-table-column>
      <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.generalNum }}
        </template>
      </el-table-column>
      <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.oneTypeName }}
        </template>
      </el-table-column>
      <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
        </template>
      </el-table-column>
      <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.identified == 0 ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ getTypeList(scope.row.collectionData.collectionType) }}
        </template>
      </el-table-column>
      <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.integrityInfo }}
        </template>
      </el-table-column>
      <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectionData.integrity }}
        </template>
      </el-table-column>
    </el-table>


    <div class="text_center dialog-footer" slot="footer">
      <el-button size="small" @click="handleClose()">取消</el-button>
      <el-button size="small" v-noMoreClick type="primary" @click="aubAllData()">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "confirmOutbound",
  data() {
    return {
      id: '',
      title: '确定出库',
      dialogVisible: false,
      typeList: [],
      leaveList: [],

      generalList: [],
      auxiliaryList: [],
      num: 0,
      type: 0, //1详情页接口
    }
  },

  mounted() {
    this.$axios(this.api.collection.listSelect).then(data => {
      if (data.status) {
        this.typeList = data.data
      }
    })

    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
  },

  methods: {
    init(id, num, selectGeneralList, selectAuxiliaryList, type) {
      this.id = id
      this.num = num
      this.type = type
      if (selectGeneralList) { //详情
        this.generalList = selectGeneralList
        this.auxiliaryList = selectAuxiliaryList
        this.dialogVisible = true
        if (this.num == 0) {
          this.title = '确定出库'
        }
        if (this.num == 1) {
          this.title = '确定回库'
        }
        if (this.num == 2) {
          this.title = '确定收货'
        }
      } else {
        this.$axios(this.api.collection.getCollectionWarehouseOrderById + this.id, {}, 'get').then(data => {
          if (data.status) {
            this.inputForm = data.data
            this.dialogVisible = true
            if (this.num == 0) {
              this.title = '确定出库'
              this.generalList = data.data.collectionGeneralOrderItems.filter(item => {
                return item.storeState == 5
              })
              this.auxiliaryList = data.data.collectionHelpOrderItems.filter(item => {
                return item.storeState == 5
              })
            } else {
              this.title = '确认收货'
              this.generalList = data.data.collectionGeneralOrderItems.filter(item => {
                return item.storeState == 6
              })
              this.auxiliaryList = data.data.collectionHelpOrderItems.filter(item => {
                return item.storeState == 6
              })
            }
          }
        })
      }
    },

    aubAllData() {
      let promptLabel = '出库'
      if (this.num == 1) {
        promptLabel = '回库'
      }
      if (this.num == 2) {
        promptLabel = '收货'
      }
      if (this.type == 1) {
        let data = []
        this.generalList.forEach(item => {
          data.push({
            id: item.id,
            type: this.num,
          })
        })
        this.auxiliaryList.forEach(item => {
          data.push({
            id: item.id,
            type: this.num,
          })
        })
        this.$axios(this.api.collection.collectionwarehouseorderOutbound, data, 'post').then(data => {
          if (data.status) {
            this.$message.success(`${promptLabel}成功`)
            this.$emit('chuKuad')
            this.handleClose()
          } else {
            this.$message.error(data.msg)
          }
        })
      } else {
        this.$axios(this.api.collection.confirmOutbound, {
          id: this.id,
          type: this.num,
        }, 'post').then(data => {
          if (data.status) {
            this.$message.success(`${promptLabel}` + '成功')
            this.$emit('chuKuad')
            this.handleClose()
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    },

    handleClose() {
      this.dialogVisible = false
    },

    getTypeList(type) {
      let data = this.typeList.filter(item => {
        return item.id == type
      })
      if (data.length) {
        return data[0].collectionTypeName
      }
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },
  },
}
</script>

<style scoped>
.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}
</style>